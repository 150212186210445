import React from "react";
import { graphql } from "gatsby";
import { Row } from "react-bootstrap";

import Layout from "../components/layout";
import Title from "../components/Title";
import BlogPost from "../components/BlogPost";
import Seo from "../components/seo";

const Blog = ({ data, location, path }) => {
  const posts = data.allContentfulBlogPost.nodes;
  return (
    <Layout location={location} path={path}>
      <Seo title="Blog" />
      <Row className="pt-5">
        <Title title="Blog" position="center" pageTitle />
      </Row>
      {posts.map((post, idx) => {
        return <BlogPost post={post} key={idx} />;
      })}
    </Layout>
  );
};

export default Blog;

export const data = graphql`
  query BlogQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        previewText {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
